import {createApi} from "@reduxjs/toolkit/dist/query/react";
import customFetchBase from "../customFetchBase";
import {GenericResponse} from "../types";

export const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: customFetchBase,
    tagTypes: ['fileResponseDTO'],
    endpoints: (builder) => ({
        newFileUpload: builder.mutation<GenericResponse, { file: any, fileAttachmentTypeId: number }>({
            query(data) {
                const formData = new FormData();

                formData.append("file", data.file);

                return {
                    url: `/file/upload?type=${data.fileAttachmentTypeId}`,
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['fileResponseDTO'],
        }),
    })
});

export const {
    useNewFileUploadMutation
} = fileApi;
