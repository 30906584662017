import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import SetupReducer from './apps/setup/SetupSlice';
import ListReducer from './apps/lists/ListSlice';
import EmailReducer from './apps/email/EmailSlice';
import DashboardReducer from './apps/dashboard/DashboardSlice';
import { combineReducers } from 'redux';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { sourceApi } from './api/control/sourceApi';
import { categoryApi } from './api/control/categoryApi';
import { controlApi } from './api/control/controlApi';
import { observationApi } from './api/control/observationApi';
import { engagementApi } from './api/engagementApi';
import { workProgramApi } from './api/workProgramApi';
import { roleApi } from './api/roleApi';
import { userApi } from './api/userApi';
import { organizationUnitApi } from './api/organizationUnitApi';
import { auditAreaApi } from './api/auditAreaApi';
import { rtkQueryErrorLogger } from './errorInterceptor';
import AuthReducer from './feature/authSlice';
import { dashboardApi } from './api/reports/dashboardApi';
import { riskFactorApi } from './api/risk/riskFactorApi';
import { riskFactorCriteriaApi } from './api/risk/riskFactorCriteriaApi';
import { riskFactorAssessmentApi } from './api/risk/riskFactorAssessmentApi';
import { riskPlanApi } from './api/risk/riskPlanApi';
import { fileApi } from './api/fileApi';
import { bookObservationApi } from './api/bookObservationApi';
import { reviewApi } from './api/reviewApi';
import { complianceApi } from './api/complianceApi';
import { auditQuarterApi } from './api/setups/auditQuarterApi';
import { generalSetupApi } from './api/setups/generalSetupApi';
import { checklistApi } from './api/control/checklistApi';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    setupReducer: SetupReducer,
    listReducer: ListReducer,
    authState: AuthReducer,
    dashboard: DashboardReducer,
    emailReducer: EmailReducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [sourceApi.reducerPath]: sourceApi.reducer,
    [controlApi.reducerPath]: controlApi.reducer,
    [observationApi.reducerPath]: observationApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [checklistApi.reducerPath]: checklistApi.reducer,
    [engagementApi.reducerPath]: engagementApi.reducer,
    [workProgramApi.reducerPath]: workProgramApi.reducer,
    [bookObservationApi.reducerPath]: bookObservationApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [organizationUnitApi.reducerPath]: organizationUnitApi.reducer,
    [auditAreaApi.reducerPath]: auditAreaApi.reducer,
    [riskFactorApi.reducerPath]: riskFactorApi.reducer,
    [riskFactorCriteriaApi.reducerPath]: riskFactorCriteriaApi.reducer,
    [riskFactorAssessmentApi.reducerPath]: riskFactorAssessmentApi.reducer,
    [riskPlanApi.reducerPath]: riskPlanApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [complianceApi.reducerPath]: complianceApi.reducer,
    [auditQuarterApi.reducerPath]: auditQuarterApi.reducer,
    [generalSetupApi.reducerPath]: generalSetupApi.reducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      sourceApi.middleware,
      categoryApi.middleware,
      controlApi.middleware,
      observationApi.middleware,
      checklistApi.middleware,
      engagementApi.middleware,
      workProgramApi.middleware,
      bookObservationApi.middleware,
      reviewApi.middleware,
      complianceApi.middleware,
      roleApi.middleware,
      userApi.middleware,
      organizationUnitApi.middleware,
      auditAreaApi.middleware,
      dashboardApi.middleware,
      riskFactorApi.middleware,
      riskFactorCriteriaApi.middleware,
      riskFactorAssessmentApi.middleware,
      riskPlanApi.middleware,
      fileApi.middleware,
      auditQuarterApi.middleware,
      generalSetupApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  setupReducer: SetupReducer,
  listReducer: ListReducer,
  authState: AuthReducer,
  dashboard: DashboardReducer,
  emailReducer: EmailReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
