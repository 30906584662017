import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewWorkProgramInput } from 'src/views/pages/execution/workprogram/Create';
// import { NewControlLibraryInput } from 'src/content/pages/Management/Controls/Library/Create';
// import { NewWorkProgramInput } from 'src/content/pages/Planning/WorkProgram/Create';
import {
  WorkProgramResponse,
  GenericResponse,
  User,
  ControlCategoryResponse,
  ControlLibraryResponse,
  EngagementResponse,
} from '../types';
import customFetchBase from 'src/store/customFetchBase';
import { NewUploadAnnexuresInput } from 'src/views/pages/execution/workprogram/Annexures';
import { NewControlLibraryInput } from 'src/views/pages/setup/controls/library';

export const workProgramApi = createApi({
  reducerPath: 'workProgramApi',
  baseQuery: customFetchBase,
  tagTypes: ['workProgramResponseDTO'],
  endpoints: (builder) => ({
    newWorkProgram: builder.mutation<WorkProgramResponse, NewWorkProgramInput[]>({
      query(data) {
        return {
          url: '/workProgram/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['workProgramResponseDTO'],
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse }) =>
        data.workProgramResponseDTO,
    }),
    newUploadAnnexures: builder.mutation<string, NewUploadAnnexuresInput[]>({
      query(data) {
        return {
          url: '/workProgram/uploadAnnexure',
          method: 'POST',
          body: data,
        };
      },
    }),
    // updateWorkProgram: builder.mutation<WorkProgramResponse, NewWorkProgramInput>({
    //   query(data) {
    //     return {
    //       url: `/update/${data.id}`,
    //       method: 'PUT',
    //       body: data
    //     };
    //   },
    //   invalidatesTags: ['WorkProgram'],
    //   transformResponse: (data: { result: WorkProgramResponse }) => data.result
    // }),
    updateWorkProgram: builder.mutation<WorkProgramResponse, NewControlLibraryInput>({
      query(data) {
        return {
          url: `/workProgramReview/review/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      // invalidatesTags: ['auditEngagementResponseDTO'],
      transformResponse: (data: { auditEngagementResponseDTO: WorkProgramResponse }) =>
        data.auditEngagementResponseDTO,
    }),
    executeControl: builder.mutation<WorkProgramResponse, NewWorkProgramInput>({
      query(data) {
        return {
          url: `/WorkProgramExecution/execute/${data.auditEngagementId}`,
          method: 'PUT',
          body: data,
        };
      },
      // invalidatesTags: ['auditEngagementResponseDTO'],
      transformResponse: (data: { auditEngagementResponseDTO: WorkProgramResponse }) =>
        data.auditEngagementResponseDTO,
    }),
    deleteControl: builder.mutation<WorkProgramResponse, string>({
      query(id) {
        return {
          url: `/workProgramReview/delete/${id}`,
          method: 'DELETE',
        };
      },
      // invalidatesTags: ['auditEngagementResponseDTO'],
      transformResponse: (data: { auditEngagementResponseDTO: WorkProgramResponse }) =>
        data.auditEngagementResponseDTO,
    }),
    getExecutionWorkPrograms: builder.query<WorkProgramResponse[], { id: number; status: number }>({
      query({ id, status }) {
        return {
          url: `/WorkProgramExecution/getWorkProgram/${id}/${status}`,
          credentials: 'include',
        };
      },
      providesTags: ['workProgramResponseDTO'],
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
    getReviewWorkPrograms: builder.query<WorkProgramResponse[], { id: number; status: number }>({
      query({ id, status }) {
        return {
          url: `/workProgramReview/getWorkProgram/${id}/${status}`,
          credentials: 'include',
        };
      },
      providesTags: ['workProgramResponseDTO'],
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
    getEngagementsByTeam: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/workProgram/getEngagementsByUser',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getControlsByEngagement: builder.query<ControlLibraryResponse[], string>({
      query(id: string) {
        return {
          url: `/workProgram/getControls/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlResponseDTO: ControlLibraryResponse[] }) =>
        data.auditControlResponseDTO,
    }),
    getControlsByWorkprogram: builder.query<ControlLibraryResponse[], string>({
      query(id: string) {
        return {
          url: `/workProgram/getControls/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlResponseDTO: ControlLibraryResponse[] }) =>
        data.auditControlResponseDTO,
    }),
    getAuditTeam: builder.query<User[], void>({
      query() {
        return {
          url: '/workProgram/getAllUsers',
          credentials: 'include',
        };
      },
      transformResponse: (data: { result: User[] }) => data.result,
    }),
    getConclusion: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/workProgram/getConclusions',
          credentials: 'include',
        };
      },
      transformResponse: (data: { workProgramConclusionResponseDTO: GenericResponse[] }) =>
        data.workProgramConclusionResponseDTO,
    }),
    getAttachmentTypes: builder.query<GenericResponse[], number[]>({
      query(ids) {
        return {
          url: `/workProgram/attachmentType?Ids=${ids}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fileAttachmentTypeResponseDTO: GenericResponse[] }) =>
        data.fileAttachmentTypeResponseDTO,
    }),
    getWorkProgramsByEngagement: builder.query<
      WorkProgramResponse[],
      { engid: bigint; rvid: number }
    >({
      query({ engid, rvid }) {
        return {
          url: `/viewWorkProgram/getWorkProgram/${engid}/${rvid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
  }),
});

export const {
  useNewWorkProgramMutation,
  useNewUploadAnnexuresMutation,
  useUpdateWorkProgramMutation,
  useDeleteControlMutation,
  useExecuteControlMutation,
  // useGetAllWorkProgramsQuery,
  useLazyGetExecutionWorkProgramsQuery,
  useLazyGetReviewWorkProgramsQuery,
  useLazyGetWorkProgramsByEngagementQuery,
  useGetAuditTeamQuery,
  useLazyGetControlsByEngagementQuery,
  useLazyGetControlsByWorkprogramQuery,
  useGetEngagementsByTeamQuery,
  useGetConclusionQuery,
  useGetAttachmentTypesQuery,
} = workProgramApi;
