import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { NewControlCategoryInput } from 'src/content/pages/Management/Controls/Category/Create';
import { ControlCategoryResponse, ControlCountResponse, GenericResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditControlCategoryResponseDTO'],
  endpoints: (builder) => ({
    newControlCategory: builder.mutation<ControlCategoryResponse, any>({
      query(data) {
        return {
          url: '/category/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditControlCategoryResponseDTO'],
    }),
    updateControlCategory: builder.mutation<ControlCategoryResponse, any>({
      query(data) {
        return {
          url: `/category/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditControlCategoryResponseDTO'],
    }),
    getAllCategories: builder.query<ControlCategoryResponse[], void>({
      query() {
        return {
          url: '/category/getall',
          credentials: 'include',
        };
      },
      providesTags: ['auditControlCategoryResponseDTO'],
      transformResponse: (data: { auditControlCategoryResponseDTO: ControlCategoryResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
    getCategoriesCounts: builder.query<ControlCountResponse[], void>({
      query() {
        return {
          url: '/category/getcounts',
          credentials: 'include',
        };
      },
      transformResponse: (data: { controlCountResponseDTO: ControlCountResponse[] }) =>
        data.controlCountResponseDTO,
    }),
    getCategoryByID: builder.query<ControlCategoryResponse[], string>({
      query(id: string) {
        return {
          url: `/category/get/${id}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditControlCategoryResponseDTO'],
      transformResponse: (data: { auditControlCategoryResponseDTO: ControlCategoryResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
    getSections: builder.query<GenericResponse[], string>({
      query(Identifier: string) {
        return {
          url: `/category/getSection?${Identifier}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditAreaTypeMasterResponseDTO: GenericResponse[] }) =>
        data.auditAreaTypeMasterResponseDTO,
    }),
  }),
});

export const {
  useNewControlCategoryMutation,
  useUpdateControlCategoryMutation,
  useGetAllCategoriesQuery,
  useGetCategoriesCountsQuery,
  useGetCategoryByIDQuery,
  useLazyGetSectionsQuery,
} = categoryApi;
