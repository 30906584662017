import React from 'react';
import { Grid, Typography, Box, Breadcrumbs, Link, Theme } from '@mui/material';
import { NavLink } from 'react-router-dom';

import breadcrumbImg from 'src/assets/images/backgrounds/piggy.png';
import { IconCircle } from '@tabler/icons';

interface BreadCrumbType {
  subtitle?: string;
  items?: any[];
  title: string;
  children?: JSX.Element;
}

const Breadcrumb = ({ subtitle, items, title, children }: BreadCrumbType) => (
  <Grid
    container
    sx={{
      backgroundColor: 'primary.light',
      borderRadius: (theme: Theme) => Number(theme.shape.borderRadius) / 4,
      p: '8px 25px 8px',
      marginBottom: '10px',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <Grid item xs={12} mb={1}>
      <Typography variant="h4">{title}</Typography>
      {/* <Typography color="textSecondary" variant="h6" fontWeight={400} mt={0.8} mb={0}>
        {subtitle}
      </Typography> */}
      {(items || []).length > 1 && (
        <Breadcrumbs
          separator={
            <IconCircle
              size="5"
              fill="textSecondary"
              fillOpacity={'0.6'}
              style={{ margin: '0 5px' }}
            />
          }
          sx={{ alignItems: 'center', mt: items ? '10px' : '' }}
          aria-label="breadcrumb"
        >
          {items
            ? items.map((item) => (
                <div key={item.title}>
                  {item.to ? (
                    <Link
                      underline="none"
                      color="inherit"
                      component={NavLink}
                      to={item.to}
                      state={item.state ? item.state : undefined}
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <Typography color="textPrimary">{item.title}</Typography>
                  )}
                </div>
              ))
            : ''}
        </Breadcrumbs>
      )}
    </Grid>
    <Grid item xs={12} sm={6} lg={4} display="flex" alignItems="flex-end">
      <Box
        sx={{
          display: { xs: 'none', md: 'block', lg: 'flex' },
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {children ? (
          <Box sx={{ top: '0px', position: 'absolute' }}>{children}</Box>
        ) : (
          <>
            <Box sx={{ top: '0px', position: 'absolute' }}>
              {/* <img src={breadcrumbImg} alt={breadcrumbImg} width={'165px'} /> */}
              {/* {addButtonTitle && (
                <Button
                  sx={{
                    mt: { xs: 2, sm: 0 }
                  }}
                  onClick={addButtonAction}
                  variant="contained"
                  startIcon={<AddTwoToneIcon fontSize="small" />}
                  color="secondary"
                >
                  {addButtonTitle}
                </Button>
              )} */}
            </Box>
          </>
        )}
      </Box>
    </Grid>
  </Grid>
);

export default Breadcrumb;
