import React from 'react';

import { useSelector } from 'src/store/Store';
import {
  ListItemText,
  Box,
  Avatar,
  ListItemButton,
  Typography,
  Stack,
  ListItemAvatar,
  useTheme,
  Tooltip,
} from '@mui/material';

import { IconStar, IconTrash } from '@tabler/icons';
import * as utils from 'src/utils/common';
import { wrap } from 'module';

type SetupListItemProps = {
  onSetupClick: (event: React.MouseEvent<HTMLElement>) => void;
  onStarredClick: React.MouseEventHandler<SVGElement>;
  onDeleteClick: React.MouseEventHandler<SVGElement>;
  id: string | number;
  title: string;
  description: string;
  name: string;
  exception: string;
  detail: string;
  effectiveDate: Date;
  address: string;
  active: any;
};

const SetupListItem = ({
  onSetupClick,
  onStarredClick,
  onDeleteClick,
  id,
  title,
  description,
  name,
  exception,
  effectiveDate,
  address,
  detail,
  active,
}: SetupListItemProps) => {
  const customizer = useSelector((state) => state.customizer);
  // const br = `${customizer.borderRadius}px`;

  const theme = useTheme();

  // const warningColor = theme.palette.warning.main;

  return (
    <ListItemButton sx={{ mb: 1 }} selected={active} onClick={onSetupClick}>
      {/* <ListItemAvatar>
        <IconStar size="16" stroke={1.5} />
      </ListItemAvatar> */}
      <ListItemText>
        {/* <Stack direction="row" gap={5} alignItems="center"> */}
        <Box mr="auto">
          <Typography variant="subtitle1" fontWeight={600}>
            {title || name || exception || detail}
          </Typography>
          <Tooltip title={description || ''}>
            <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px' }} noWrap>
              {description || utils.formattedDate(effectiveDate) || address}
            </Typography>
          </Tooltip>
        </Box>
        {/* <IconStar
            onClick={onStarredClick}
            size="16"
            stroke={1.5}
            style={{ fill: starred ? warningColor : '', stroke: starred ? warningColor : '' }}
          /> */}
        {/* <IconTrash onClick={onDeleteClick} size="16" stroke={1.5} /> */}
        {/* </Stack> */}
      </ListItemText>
    </ListItemButton>
  );
};

export default SetupListItem;
