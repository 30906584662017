import { createApi } from '@reduxjs/toolkit/query/react';
import { NewEngagementInput } from 'src/views/pages/planning/engagement/new';
import {
  AssessmentCriteriaResponse,
  AuditAreaResponse,
  ChecklistResponse,
  ControlCategoryResponse,
  ControlLibraryResponse,
  EngagementAuditTeamResponse,
  EngagementResponse,
  GenericResponse,
  User
} from '../types';
import customFetchBase from 'src/store/customFetchBase';

export const engagementApi = createApi({
  reducerPath: 'engagementApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditEngagementResponseDTO'],
  endpoints: (builder) => ({
    newEngagement: builder.mutation<EngagementResponse, NewEngagementInput>({
      query(data) {
        return {
          url: '/engagement/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['auditEngagementResponseDTO']
    }),
    newEngagementResource: builder.mutation<EngagementResponse, any[]>({
      query(data) {
        return {
          url: `/engagementPlanning/assignTeam/${data[0].id}`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['auditEngagementResponseDTO']
    }),
    updateEngagement: builder.mutation<EngagementResponse, NewEngagementInput>({
      query(data) {
        return {
          url: `/engagement/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['auditEngagementResponseDTO']
    }),
    getAllEngagements: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/engagement/getall',
          credentials: 'include'
        };
      },
      providesTags: ['auditEngagementResponseDTO'],
      transformResponse: (data: {
        auditEngagementResponseDTO: EngagementResponse[];
      }) => data.auditEngagementResponseDTO
    }),
    getPlannedEngagements: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/engagementPlanning/getall',
          credentials: 'include'
        };
      },
      providesTags: ['auditEngagementResponseDTO'],
      transformResponse: (data: {
        auditEngagementResponseDTO: EngagementResponse[];
      }) => data.auditEngagementResponseDTO
    }),
    getAuditAreas: builder.query<AuditAreaResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllArea',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreasResponseDTO: AuditAreaResponse[];
      }) => data.auditAreasResponseDTO
    }),
    getEngagementStatus: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllStatus',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        engagementStatusResponseDTO: GenericResponse[];
      }) => data.engagementStatusResponseDTO
    }),
    getEngagementTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllTypes',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        engagementTypeResponseDTO: GenericResponse[];
      }) => data.engagementTypeResponseDTO
    }),
    getAuditYears: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllYears',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        engagementYearResponseDTO: GenericResponse[];
      }) => data.engagementYearResponseDTO
    }),
    getAssestmentTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllAssessmentType',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        assessmentTypeResponseDTO: GenericResponse[];
      }) => data.assessmentTypeResponseDTO
    }),
    getChecklist: builder.query<ChecklistResponse[], void>({
      query() {
        return {
          url: '/engagement/getAllCheckLst',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditCheckListResponseDTO: ChecklistResponse[];
      }) => data.auditCheckListResponseDTO
    }),
    getAssestmentCriteria: builder.query<AssessmentCriteriaResponse[], string>({
      query(id: string) {
        return {
          url: `/engagement/getAssessmentCriteria/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        assessmentCriteriaResponseDTO: AssessmentCriteriaResponse[];
      }) => data.assessmentCriteriaResponseDTO
    }),
    getAuditTeam: builder.query<User[], void>({
      query() {
        return {
          url: '/engagement/getAllUsers',
          credentials: 'include'
        };
      },
      transformResponse: (data: { userResponseDTO: User[] }) =>
        data.userResponseDTO
    }),
    getAuditTeamByEng: builder.query<EngagementAuditTeamResponse[], string>({
      query(id) {
        return {
          url: `/engagementPlanning/getAuditTeam/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditEngagementTeamResponseDTO: EngagementAuditTeamResponse[];
      }) => data.auditEngagementTeamResponseDTO
    }),
    getStakeholders: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/engagement/getStakeHolders',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: GenericResponse[];
      }) => data.auditAreaTypeMasterResponseDTO
    }),
    getEngagementByID: builder.query<EngagementResponse[], string>({
      query(id: string) {
        return {
          url: `/engagement/get/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditEngagementResponseDTO: EngagementResponse[];
      }) => data.auditEngagementResponseDTO
    }),
    getCategories: builder.query<ControlCategoryResponse[], string>({
      query(id: string) {
        return {
          url: `/engagementPlanning/getCategories/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditControlCategoryResponseDTO: ControlCategoryResponse[];
      }) => data.auditControlCategoryResponseDTO
    }),
    
  })
});

export const {
  useNewEngagementMutation,
  useNewEngagementResourceMutation,
  useUpdateEngagementMutation,
  useGetAllEngagementsQuery,
  useGetPlannedEngagementsQuery,
  useGetEngagementByIDQuery,
  useGetAuditAreasQuery,
  useGetEngagementStatusQuery,
  useGetEngagementTypesQuery,
  useGetAuditYearsQuery,
  useGetAssestmentTypesQuery,
  useGetChecklistQuery,
  useLazyGetAssestmentCriteriaQuery,
  useGetAuditTeamQuery,
  useLazyGetAuditTeamByEngQuery,
  useGetStakeholdersQuery,
  useLazyGetCategoriesQuery,
  useLazyGetEngagementStatusQuery,
} = engagementApi;
