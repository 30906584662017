import { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorBoundary } from 'src/views/pages/status/Error';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <ThemeProvider theme={theme}>
        {/* <RTL direction={customizer.activeDir}> */}
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline />
          <ScrollToTop>
            <ErrorBoundary>{routing}</ErrorBoundary>
          </ScrollToTop>
          {/* </RTL> */}
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
