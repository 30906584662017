import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewRiskPlanInput } from 'src/views/pages/setup/risk/Assessment/Plan';
import { GenericResponse, RiskPlanResponse } from 'src/store/types';
import customFetchBase from 'src/store/customFetchBase';

export const riskPlanApi = createApi({
  reducerPath: 'riskPlanApi',
  baseQuery: customFetchBase,
  tagTypes: ['riskPlanResponseDTO'],
  endpoints: (builder) => ({
    newRiskPlan: builder.mutation<RiskPlanResponse, NewRiskPlanInput[]>({
      query(data) {
        return {
          url: '/riskAuditPlan/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['riskPlanResponseDTO']
    }),
    updateRiskPlan: builder.mutation<RiskPlanResponse, NewRiskPlanInput>({
      query(data) {
        return {
          url: `/riskAuditPlan/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['riskPlanResponseDTO']
    }),
    getAllRiskPlans: builder.query<RiskPlanResponse[], void>({
      query() {
        return {
          url: '/riskAuditPlan/getAreasByAuditor',
          credentials: 'include'
        };
      },
      providesTags: ['riskPlanResponseDTO'],
      transformResponse: (data: {
        customAuditPlanResponseDTO: RiskPlanResponse[];
      }) => data.customAuditPlanResponseDTO
    }),
    getYears: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/riskAuditPlan/getyears',
          credentials: 'include'
        };
      },
      providesTags: ['riskPlanResponseDTO'],
      transformResponse: (data: {
        engagementYearResponseDTO: GenericResponse[];
      }) => data.engagementYearResponseDTO
    })
  })
});

export const {
  useNewRiskPlanMutation,
  useUpdateRiskPlanMutation,
  useGetAllRiskPlansQuery,
  useGetYearsQuery
} = riskPlanApi;
