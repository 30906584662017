import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { NewGeneralSetupInput } from 'src/content/pages/Setups/General';
import { GeneralSetupResponse, GenericResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';

export const generalSetupApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: customFetchBase,
  tagTypes: ['generalSetupResponseDTO'],
  endpoints: (builder) => ({
    newGeneralSetup: builder.mutation<GeneralSetupResponse, { ep: string; data: {} }>({
      query({ ep, data }) {
        return {
          url: `/${ep}/create`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['generalSetupResponseDTO'],
    }),
    updateGeneralSetup: builder.mutation<
      GeneralSetupResponse,
      { ep: string; data: any }
    >({
      query({ ep, data }) {
        return {
          url: `/${ep}/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['generalSetupResponseDTO'],
    }),
    getAllGenerals: builder.query<GeneralSetupResponse[], string>({
      query(ep) {
        return {
          url: `/${ep}/getall`,
          credentials: 'include',
        };
      },
      providesTags: ['generalSetupResponseDTO'],
    }),
    getAllFields: builder.query<string[], string>({
      query(ep) {
        return {
          url: `/${ep}/getFields`,
          credentials: 'include',
        };
      },
      providesTags: ['generalSetupResponseDTO'],
      transformResponse: (data: string[]) => data,
    }),
    getGeneralByID: builder.query<GeneralSetupResponse[], { ep: string; id: string }>({
      query({ ep, id }) {
        return {
          url: `/${ep}/get/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { result: GeneralSetupResponse[] }) => data.result,
    }),
  }),
});

export const {
  useNewGeneralSetupMutation,
  useUpdateGeneralSetupMutation,
  useGetAllGeneralsQuery,
  useGetGeneralByIDQuery,
  useGetAllFieldsQuery,
} = generalSetupApi;
