import React, { ReactNode } from 'react';
import { useSelector, useDispatch } from 'src/store/Store';
import {
  Box,
  Button,
  TextField,
  Typography,
  Avatar,
  Divider,
  IconButton,
  Stack,
  Grid,
  Tooltip,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { isEdit, DeleteSetup, toggleStarredSetup } from 'src/store/apps/setup/SetupSlice';
// import { SetupType } from 'src/types/apps/setup';
import { IconPencil, IconStar, IconTrash, IconDeviceFloppy, IconHelp } from '@tabler/icons';
// import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import emailIcon from 'src/assets/images/breadcrumb/emailSv.png';
import BlankCard from 'src/components/shared/BlankCard';

interface SetupDetailsProps {
  setupTitle: string;
  children: ReactNode;
}

const SetupDetails = (props: SetupDetailsProps) => {
  const setupDetail: any = useSelector((state) => state.setupReducer.setupContent);
  const editSetup = useSelector((state) => state.setupReducer.editSetup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const warningColor = theme.palette.warning.main;

  return (
    <>
      {/* ------------------------------------------- */}
      {/* Setup Detail Part */}
      {/* ------------------------------------------- */}
      {setupDetail ? (
        <>
          {/* ------------------------------------------- */}
          {/* Header Part */}
          {/* ------------------------------------------- */}
          <Box p={3} py={2} display={'flex'} alignItems="center">
            <Stack spacing={2} direction="row">
              <Tooltip title="Helper text">
                <IconButton>
                  <IconHelp color="grey" size={24} />
                </IconButton>
              </Tooltip>

              <Typography variant="body2" fontStyle="italic">
                {/* Control source title is used to name a particular source of controls */}
              </Typography>
            </Stack>
            <Stack gap={0} direction="row" ml={'auto'}>
              {/* <Tooltip title={setupDetail.starred ? 'Unstar' : 'Star'}>
                <IconButton onClick={() => dispatch(toggleStarredSetup(setupDetail.id))}>
                  <IconStar
                    stroke={1.3}
                    size="18"
                    style={{
                      fill: setupDetail.starred ? warningColor : '',
                      stroke: setupDetail.starred ? warningColor : '',
                    }}
                  />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title={editSetup ? 'Save' : 'Edit'}>
                <IconButton onClick={() => dispatch(isEdit())}>
                  {!editSetup ? (
                    <IconPencil size="18" stroke={1.3} />
                  ) : (
                    <IconDeviceFloppy size="18" stroke={1.3} />
                  )}
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="Save">
                <IconButton onClick={() => dispatch(isEdit())}>
                  <IconDeviceFloppy size="18" stroke={1.3} />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="Delete">
                <IconButton>
                  <IconTrash size="18" stroke={1.3} />
                </IconButton>
              </Tooltip> */}
            </Stack>
          </Box>

          <Divider />
          {/* ------------------------------------------- */}
          {/* Setup Table Part */}
          {/* ------------------------------------------- */}
          <Box sx={{ overflow: 'auto' }}>
            {/* {!editSetup ? (
              <Box>
                <Box p={3}>
                  <Box display="flex" alignItems="center">
                    <Avatar
                      alt={setupDetail.image}
                      src={setupDetail.image}
                      sx={{ width: '72px', height: '72px' }}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="h6" mb={0.5}>
                        {setupDetail.firstname} {setupDetail.lastname}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" mb={0.5}>
                        {setupDetail.department}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {setupDetail.company}
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item lg={6} xs={12} mt={4}>
                      <Typography variant="body2" color="text.secondary">
                        Phone Number
                      </Typography>
                      <Typography variant="subtitle1" mb={0.5} fontWeight={600}>
                        {setupDetail.phone}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} mt={4}>
                      <Typography variant="body2" color="text.secondary">
                        Email address
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600} mb={0.5}>
                        {setupDetail.email}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} mt={4}>
                      <Typography variant="body2" color="text.secondary">
                        Address
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600} mb={0.5}>
                        {setupDetail.address}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} mt={4}>
                      <Typography variant="body2" color="text.secondary">
                        Department
                      </Typography>
                      <Typography variant="subtitle1" mb={0.5} fontWeight={600}>
                        {setupDetail.department}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} mt={4}>
                      <Typography variant="body2" color="text.secondary">
                        Company
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600} mb={0.5}>
                        {setupDetail.company}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} mt={4}>
                      <Typography variant="body2" mb={1} color="text.secondary">
                        Notes
                      </Typography>
                      <Typography variant="subtitle1" mb={0.5}>
                        {setupDetail.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box p={3} gap={1} display="flex">
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => dispatch(isEdit())}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => dispatch(DeleteSetup(setupDetail.id))}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            ) : ( */}
            <>
              <BlankCard sx={{ p: 0 }}>
                {/* <Scrollbar sx={{ height: { lg: 'calc(100vh - 360px)', md: '100vh' } }}> */}
                <Box mt={2} pt={1} px={3} py={1.5}>
                  <Grid container spacing={2}>
                    {props.children}
                  </Grid>
                </Box>
                {/* </Scrollbar> */}
              </BlankCard>
            </>
            {/* )} */}
          </Box>
        </>
      ) : (
        <Box p={3} height="50vh" display={'flex'} justifyContent="center" alignItems={'center'}>
          {/* ------------------------------------------- */}
          {/* If no Setup  */}
          {/* ------------------------------------------- */}
          <Box>
            <Typography variant="h4">Please Select a Setup</Typography>
            <br />
            <img src={emailIcon} alt={emailIcon} width={'250px'} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SetupDetails;
