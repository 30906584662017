import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { logout } from './feature/authSlice';
import { store } from 'src/store/Store';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload) {
        if ([401].includes(action.payload.status)) {
          store.dispatch(logout());

          localStorage.removeItem('accessToken');
          localStorage.removeItem('user');

          window.location.href = '/auth/login';
        } else if ([403].includes(action.payload.status)) {
          window.location.href = '/status/403';
        } else if ([400].includes(action.payload.status)) {
          if (action.payload.data) {
            if (action.payload.data.errors.length > 0) {
              toast.error(action.payload.data.errors[0].message, {
                toastId: 'error1'
              });
            } else {
              toast.error('Internal server error', {
                toastId: 'error1'
              });
            }
          }
        } else if ([400, 422, 500].includes(action.payload.status)) {
          if (action.payload.data) {
            if (action.payload.data.errors.length > 0) {
              // toast.error(action.payload.data.errors[0].message, {
              //   toastId: 'error1'
              // });
              toast.error('Internal server error', {
                toastId: 'error1'
              });
              console.log(action.payload.data.errors);
            } else {
              toast.error('Internal server error', {
                toastId: 'error1'
              });
            }
          } else {
            toast.error('Internal server error', {
              toastId: 'error1'
            });
          }
        } else if ([404, 405].includes(action.payload.status)) {
          toast.error(
            'The requested resource is not found or invalid method call',
            {
              toastId: 'error1'
            }
          );
        } else {
          toast.error('Request rejected due to network error', {
            toastId: 'error1'
          });
        }
      }
    }

    return next(action);
  };
