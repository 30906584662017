import { createApi } from '@reduxjs/toolkit/query/react';
import { ControlSourceResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';
import { NewControlSourceInput } from 'src/views/pages/setup/controls/source';

export const sourceApi = createApi({
  reducerPath: 'sourceApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditControlSourceResponseDTO'],
  endpoints: (builder) => ({
    newControlSource: builder.mutation<
      ControlSourceResponse,
      NewControlSourceInput
    >({
      query(data) {
        return {
          url: '/source/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['auditControlSourceResponseDTO'],
    }),
    updateControlSource: builder.mutation<
      ControlSourceResponse,
      NewControlSourceInput
    >({
      query(data) {
        return {
          url: `/source/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['auditControlSourceResponseDTO'],
    }),
    getAllSources: builder.query<ControlSourceResponse[], void>({
      query() {
        return {
          url: '/source/getall',
          credentials: 'include'
        };
      },
      providesTags: ['auditControlSourceResponseDTO'],
      transformResponse: (data: {
        auditControlSourceResponseDTO: ControlSourceResponse[];
      }) => data.auditControlSourceResponseDTO
    }),
    getSourceByID: builder.query<ControlSourceResponse[], string>({
      query(id: string) {
        return {
          url: `/source/get/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: { result: ControlSourceResponse[] }) =>
        data.result
    })
  })
});

export const {
  useNewControlSourceMutation,
  useUpdateControlSourceMutation,
  useGetAllSourcesQuery,
  useGetSourceByIDQuery
} = sourceApi;
