import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setUser } from '../feature/authSlice';
import {
  ApplicationNotificationResponse,
  User,
  UserDesignation,
  UserOrganizationUnit,
} from '../types';
// import { NewUserInput } from 'src/views/pages/setup/user/create';
import customFetchBase from 'src/store/customFetchBase';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query() {
        return {
          url: 'auth/profile',
          credentials: 'include',
        };
      },
      transformResponse: (data: { editProfileResponseDTO: User }) => data.editProfileResponseDTO,
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(setUser(data));
      //   } catch (error) {}
      // }
    }),
    createUser: builder.mutation<User, {}>({
      query(user) {
        return {
          url: 'user/create',
          method: 'POST',
          credentials: 'include',
          body: user,
        };
      },
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
      transformResponse: (data: { result: User }) => data.result,
    }),
    updateUser: builder.mutation<User, {}>({
      query(user) {
        return {
          url: 'user/update',
          method: 'PUT',
          credentials: 'include',
          body: user,
        };
      },
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
      transformResponse: (data: { result: User }) => data.result,
    }),
    markNotificationAsRead: builder.mutation<boolean, number>({
      query(nid: number) {
        return {
          url: `notification/markread/${nid}`,
          method: 'PUT',
          credentials: 'include',
        };
      },
    }),
    getAllUsers: builder.query<User[], void>({
      query() {
        return {
          url: 'user/getall',
          credentials: 'include',
        };
      },
      transformResponse: (data: { userResponseDTO: User[] }) => data.userResponseDTO,
    }),
    getDesignations: builder.query<UserDesignation[], void>({
      query() {
        return {
          url: '/designation/getall',
          credentials: 'include',
        };
      },
      transformResponse: (data: { designationResponseDTO: UserDesignation[] }) =>
        data.designationResponseDTO,
    }),
    getMainOrganizationUnits: builder.query<UserOrganizationUnit[], void>({
      query() {
        return {
          url: '/user/getMainAreaMaster',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditAreaTypeMasterResponseDTO: UserOrganizationUnit[] }) =>
        data.auditAreaTypeMasterResponseDTO,
    }),
    getOrganizationUnitsByMain: builder.query<UserOrganizationUnit[], string>({
      query(id: string) {
        return {
          url: `/user/getMainAreaMasterById/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditAreaTypeMasterResponseDTO: UserOrganizationUnit[] }) =>
        data.auditAreaTypeMasterResponseDTO,
    }),
    getNotifications: builder.query<ApplicationNotificationResponse[], void>({
      query() {
        return {
          url: `/notification/nbox`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        applicationNotificationResponseDTO: ApplicationNotificationResponse[];
      }) => data.applicationNotificationResponseDTO,
    }),
    getEmails: builder.query<ApplicationNotificationResponse[], void>({
      query() {
        return {
          url: `/notification/ebox`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        applicationNotificationResponseDTO: ApplicationNotificationResponse[];
      }) => data.applicationNotificationResponseDTO,
    }),
  }),
});

export const {
  useGetMeQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useMarkNotificationAsReadMutation,
  useGetAllUsersQuery,
  useGetDesignationsQuery,
  useGetMainOrganizationUnitsQuery,
  useLazyGetOrganizationUnitsByMainQuery,
  useGetNotificationsQuery,
  useGetEmailsQuery
} = userApi;
