import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { NewAuditQuarterInput } from 'src/content/pages/Setups/AuditQuarter';
import { AuditQuarterResponse, ControlCategoryResponse, GenericResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';

export const auditQuarterApi = createApi({
  reducerPath: 'auditQuarterApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditQuarterResponseDTO'],
  endpoints: (builder) => ({
    newAuditQuarter: builder.mutation<AuditQuarterResponse, {}>({
      query(data) {
        return {
          url: '/auditQuarter/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditQuarterResponseDTO'],
    }),
    updateAuditQuarter: builder.mutation<AuditQuarterResponse, {}>({
      query(data: any) {
        return {
          url: `/auditQuarter/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditQuarterResponseDTO'],
    }),
    getAllAuditQuarters: builder.query<AuditQuarterResponse[], void>({
      query() {
        return {
          url: '/auditQuarter/getall',
          credentials: 'include',
        };
      },
      providesTags: ['auditQuarterResponseDTO'],
      transformResponse: (data: { auditQuarterResponseDTO: AuditQuarterResponse[] }) =>
        data.auditQuarterResponseDTO,
    }),
    getAuditQuarterByID: builder.query<AuditQuarterResponse[], string>({
      query(id: string) {
        return {
          url: `/auditQuarter/get/${id}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditQuarterResponseDTO'],
      transformResponse: (data: { auditQuarterResponseDTO: AuditQuarterResponse[] }) =>
        data.auditQuarterResponseDTO,
    }),
  }),
});

export const {
  useNewAuditQuarterMutation,
  useUpdateAuditQuarterMutation,
  useGetAllAuditQuartersQuery,
  useGetAuditQuarterByIDQuery,
} = auditQuarterApi;
