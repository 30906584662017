import React from 'react';
import { useSelector, useDispatch, AppState } from 'src/store/Store';
import { Box, Fab, TextField, InputAdornment } from '@mui/material';

import { SearchSetup } from 'src/store/apps/setup/SetupSlice';
import { IconMenu2, IconSearch } from '@tabler/icons';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const SetupSearch = ({ onClick }: Props) => {
  const searchTerm = useSelector((state: AppState) => state.setupReducer.setupSearch);
  const dispatch = useDispatch();

  return (
    <Box display="flex" sx={{ p: 2 }}>
      <Fab
        onClick={onClick}
        color="primary"
        size="small"
        sx={{ mr: 1, flexShrink: '0', display: { xs: 'block', lineHeight: '10px', lg: 'none' } }}
      >
        <IconMenu2 width="16" />
      </Fab>
      <TextField
        id="outlined-basic"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconSearch size={'16'} />
            </InputAdornment>
          ),
        }}
        fullWidth
        size="small"
        value={searchTerm}
        placeholder="Search setups"
        variant="outlined"
        onChange={(e) => dispatch(SearchSetup(e.target.value))}
      />
    </Box>
  );
};

export default SetupSearch;
