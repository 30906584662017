import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewRiskFactorCriteriaInput } from 'src/views/pages/setup/risk/Criteria';
import {
  RiskFactorCriteriaViewResponse,
  RiskFactorCriteriaResponse,
  GenericResponse,
  RiskFactorResponse
} from 'src/store/types';
import customFetchBase from 'src/store/customFetchBase';

export const riskFactorCriteriaApi = createApi({
  reducerPath: 'riskFactorCriteriaApi',
  baseQuery: customFetchBase,
  tagTypes: ['riskFactorCriteriaResponseDTO'],
  endpoints: (builder) => ({
    newRiskFactorCriteria: builder.mutation<RiskFactorCriteriaResponse, any[]>({
      query(data) {
        return {
          url: '/riskLevel/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['riskFactorCriteriaResponseDTO']
    }),
    updateRiskFactorCriteria: builder.mutation<
      RiskFactorCriteriaResponse,
      NewRiskFactorCriteriaInput[]
    >({
      query(data) {
        return {
          url: `/riskLevel/update/${data[0].id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['riskFactorCriteriaResponseDTO']
    }),
    getAllRiskFactorCriterias: builder.query<
      RiskFactorCriteriaViewResponse[],
      void
    >({
      query() {
        return {
          url: '/riskLevel/getall',
          credentials: 'include'
        };
      },
      providesTags: ['riskFactorCriteriaResponseDTO'],
      transformResponse: (data: {
        pivotedFactorResponseDTO: RiskFactorCriteriaViewResponse[];
      }) => data.pivotedFactorResponseDTO
    }),
    getRiskFactors: builder.query<RiskFactorResponse[], void>({
      query() {
        return {
          url: '/riskLevel/getFactors',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        riskFactorResponseDTO: RiskFactorResponse[];
      }) => data.riskFactorResponseDTO
    })
  })
});

export const {
  useNewRiskFactorCriteriaMutation,
  useUpdateRiskFactorCriteriaMutation,
  useGetAllRiskFactorCriteriasQuery,
  useGetRiskFactorsQuery
} = riskFactorCriteriaApi;
