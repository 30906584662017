import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AuditAreaTypeResponse,
  GenericResponse,
  MenuResponse,
  PermissionResponse,
  AuditAreaMasterResponse,
  User,
  UserOrganizationUnit
} from '../types';
import { NewOrganizationUnitInput } from 'src/views/pages/setup/organization/unit';
import customFetchBase from 'src/store/customFetchBase';

export const organizationUnitApi = createApi({
  reducerPath: 'organizationUnitApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditAreaTypeMasterResponseDTO'],
  endpoints: (builder) => ({
    newOrganizationUnit: builder.mutation<
      UserOrganizationUnit,
      NewOrganizationUnitInput
    >({
      query(data) {
        return {
          url: '/auditMaster/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['auditAreaTypeMasterResponseDTO']
    }),
    updateOrganizationUnit: builder.mutation<
      UserOrganizationUnit,
      NewOrganizationUnitInput
    >({
      query(data) {
        return {
          url: `/auditMaster/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['auditAreaTypeMasterResponseDTO']
    }),
    getAllOrganizationUnits: builder.query<UserOrganizationUnit[], void>({
      query() {
        return {
          url: '/auditMaster/getall',
          credentials: 'include'
        };
      },
      providesTags: ['auditAreaTypeMasterResponseDTO'],
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: UserOrganizationUnit[];
      }) => data.auditAreaTypeMasterResponseDTO
    }),
    getMainOrganizationUnits: builder.query<UserOrganizationUnit[], void>({
      query() {
        return {
          url: `/auditMaster/getMain`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: UserOrganizationUnit[];
      }) => data.auditAreaTypeMasterResponseDTO
    }),
    //   query(Identifier: string) {
    //     return {
    //       url: `/auditMaster/getall/${Identifier}`,
    //       credentials: 'include'
    //     };
    //   },
    //   transformResponse: (data: {
    //     auditAreaTypeMasterResponseDTO: GenericResponse[];
    //   }) => data.auditAreaTypeMasterResponseDTO
    // }),
    // getMultipleAuditAreasByType: builder.query<GenericResponse[], string>({
    //   query(Identifier: string) {
    //     return {
    //       url: `/auditMaster/getallByIdentifiers?${Identifier}`,
    //       credentials: 'include'
    //     };
    //   },
    //   transformResponse: (data: {
    //     auditAreaTypeMasterResponseDTO: GenericResponse[];
    //   }) => data.auditAreaTypeMasterResponseDTO
    // }),
    getAllAuditAreaTypes: builder.query<AuditAreaTypeResponse[], void>({
      query() {
        return {
          url: '/auditMaster/getallTypes',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeResponseDTO: AuditAreaTypeResponse[];
      }) => data.auditAreaTypeResponseDTO
    }),
    getAllRegions: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditMaster/getAllRegions',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        regionResponseDTO: GenericResponse[];
      }) => data.regionResponseDTO
    }),
    getAllAuditors: builder.query<UserOrganizationUnit[], void>({
      query() {
        return {
          url: '/auditMaster/getAuditors',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: UserOrganizationUnit[];
      }) => data.auditAreaTypeMasterResponseDTO
    }),
    getAllAuditees: builder.query<UserOrganizationUnit[], void>({
      query() {
        return {
          url: '/auditMaster/getAuditees',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: UserOrganizationUnit[];
      }) => data.auditAreaTypeMasterResponseDTO
    })
  })
});

export const {
  useNewOrganizationUnitMutation,
  useUpdateOrganizationUnitMutation,
  // useLazyGetAllAuditAreasByTypeQuery,
  // useLazyGetMultipleAuditAreasByTypeQuery,
  useGetAllOrganizationUnitsQuery,
  useGetAllAuditAreaTypesQuery,
  useGetAllRegionsQuery,
  useGetMainOrganizationUnitsQuery,
  useGetAllAuditorsQuery,
  useGetAllAuditeesQuery
} = organizationUnitApi;
