import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import type { PayloadAction } from '@reduxjs/toolkit';

const API_URL = '/api/data/contacts/SetupsData';

interface StateType {
  setups: any[];
  setupId: number;
  setupContent: any;
  setupSearch: string;
  editSetup: boolean;
  currentFilter: string;
  totals: number;
  actives: number;
  inactives: number;
}

const initialState = {
  setups: [],
  setupId: 1,
  setupContent: {},
  setupSearch: '',
  editSetup: false,
  currentFilter: 'show_all',
  totals: 0,
  actives: 0,
  inactives: 0,
};

export const SetupSlice = createSlice({
  name: 'setups',
  initialState,
  reducers: {
    getSetups: (state: StateType, action) => {
      state.setups = action.payload;

      if (state.setups) {
        state.totals = state.setups.length;
        state.actives = state.setups.filter(
          (item) => item.isActive === undefined || item.isActive,
        ).length;
        state.inactives = state.setups.filter(
          (item) => item.isActive !== undefined && !item.isActive,
        ).length;
      }
    },
    SearchSetup: (state: StateType, action) => {
      state.setupSearch = action.payload;
    },
    SelectSetup: (state: StateType, action) => {
      state.setupId = action.payload.id;
      state.setupContent = action.payload;
      state.editSetup = true;
    },
    DeleteSetup: (state: StateType, action) => {
      state.setups = state.setups.map((setup) =>
        setup.id === action.payload ? { ...setup, deleted: !setup.deleted } : setup,
      );
    },
    reset: (state: StateType) => {
      state.setupContent = {};
      state.editSetup = false;
    },
    ClearSetup: (state: StateType) => {
      state = initialState;
    },
    toggleStarredSetup: (state: StateType, action) => {
      state.setups = state.setups.map((setup) =>
        setup.id === action.payload ? { ...setup, starred: !setup.starred } : setup,
      );
    },
    isEdit: (state: StateType) => {
      state.editSetup = !state.editSetup;
    },
    setVisibilityFilter: (state: StateType, action) => {
      state.currentFilter = action.payload;
    },

    // UpdateSetup: {
    //   reducer: (state: StateType, action: PayloadAction<any>) => {
    //     state.setups = state.setups.map((setup) =>
    //       setup.id === action.payload.id
    //         ? { ...setup, [action.payload.field]: action.payload.value }
    //         : setup,
    //     );
    //   },
    //   prepare: (id, field, value) => {
    //     return {
    //       payload: { id, field, value },
    //     };
    //   },
    // },
    // addSetup: {
    //   reducer: (state: StateType, action: PayloadAction<any>) => {
    //     state.setups.push(action.payload);
    //   },
    //   prepare: (
    //     id,
    //     firstname,
    //     lastname,
    //     image,
    //     department,
    //     company,
    //     phone,
    //     email,
    //     address,
    //     notes,
    //   ) => {
    //     return {
    //       payload: {
    //         id,
    //         firstname,
    //         lastname,
    //         image,
    //         department,
    //         company,
    //         phone,
    //         email,
    //         address,
    //         notes,
    //         frequentlysetuped: false,
    //         starred: false,
    //         deleted: false,
    //       },
    //     };
    //   },
    // },
  },
});

export const {
  getSetups,
  SearchSetup,
  isEdit,
  SelectSetup,
  DeleteSetup,
  toggleStarredSetup,
  reset,
  ClearSetup,
  // UpdateSetup,
  // addSetup,
  setVisibilityFilter,
} = SetupSlice.actions;

export const fetchSetups = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${API_URL}`);
    dispatch(getSetups(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default SetupSlice.reducer;
