import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AuditAreaResponse,
  NewAuditAreaInput,
  GenericResponse,
  MenuResponse,
  PermissionResponse,
  User
} from '../types';
import { NewAuditUniverseInput } from 'src/views/pages/setup/organization/universe';
import customFetchBase from 'src/store/customFetchBase';

export const auditAreaApi = createApi({
  reducerPath: 'auditAreaApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditAreasResponseDTO'],
  endpoints: (builder) => ({
    newAuditArea: builder.mutation<GenericResponse, NewAuditUniverseInput>({
      query(data) {
        return {
          url: '/auditArea/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['auditAreasResponseDTO']
    }),
    updateAuditArea: builder.mutation<GenericResponse, NewAuditUniverseInput>({
      query(data) {
        return {
          url: `/auditArea/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['auditAreasResponseDTO']
    }),
    getAllAuditAreas: builder.query<AuditAreaResponse[], void>({
      query() {
        return {
          url: `/auditArea/getall`,
          credentials: 'include'
        };
      },
      providesTags: ['auditAreasResponseDTO'],
      transformResponse: (data: {
        customAuditAreasResponseDTO: AuditAreaResponse[];
      }) => data.customAuditAreasResponseDTO
    }),
    getAuditAreasByID: builder.query<AuditAreaResponse[], bigint>({
      query(id: bigint) {
        return {
          url: `/auditArea/get/${id}`,
          credentials: 'include'
        };
      },
      providesTags: ['auditAreasResponseDTO'],
      transformResponse: (data: {
        auditAreasResponseDTO: AuditAreaResponse[];
      }) => data.auditAreasResponseDTO
    }),
    getAllAuditAreaTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditArea/getallTypes',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeResponseDTO: GenericResponse[];
      }) => data.auditAreaTypeResponseDTO
    }),
    getAllAuditAreaMasters: builder.query<GenericResponse[], void>({
      query() {
        return {
          // url: `/auditArea/getMasters/${id}`,
          url: `/auditArea/getAuditees`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreaTypeMasterResponseDTO: GenericResponse[];
      }) => data.auditAreaTypeMasterResponseDTO
    })
  })
});

export const {
  useNewAuditAreaMutation,
  useUpdateAuditAreaMutation,
  useGetAllAuditAreaTypesQuery,
  useLazyGetAllAuditAreaTypesQuery,
  useGetAllAuditAreasQuery,
  useLazyGetAllAuditAreasQuery,
  useLazyGetAuditAreasByIDQuery,
  useGetAllAuditAreaMastersQuery
} = auditAreaApi;
