import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';

type DashboardState = {
  data: any;
  categories: string[];
  summary: { rect: number; unrect: number; high: number; penalized: number };
  isFetching: boolean;
  isSuccess: boolean;
};

const initialState: DashboardState = {
  data: {},
  categories: [],
  summary: { rect: 0, unrect: 0, high: 0, penalized: 0 },
  isFetching: false,
  isSuccess: false,
};

export const DashboardSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSummary: (state, action) => {
      state.summary.rect = action.payload.rect;
      state.summary.unrect = action.payload.unrect;
      state.summary.high = action.payload.high;
      state.summary.penalized = action.payload.penalized;
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
});

export const { setData, setCategories, setSummary, setFetching, setSuccess } =
  DashboardSlice.actions;

export const fetchData = (data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setData(data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetchCategories = (cats: string[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCategories(cats));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetchSummary = (summary: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setSummary(summary));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetching = (flag: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setFetching(flag));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetched = (flag: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setSuccess(flag));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default DashboardSlice.reducer;
