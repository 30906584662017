import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewRiskFactorInput } from 'src/views/pages/setup/risk/Factor';
import { RiskFactorResponse, GenericResponse } from 'src/store/types';
import customFetchBase from 'src/store/customFetchBase';

export const riskFactorApi = createApi({
  reducerPath: 'riskFactorApi',
  baseQuery: customFetchBase,
  tagTypes: ['riskFactorResponseDTO'],
  endpoints: (builder) => ({
    newRiskFactor: builder.mutation<
      RiskFactorResponse,
      NewRiskFactorInput
    >({
      query(data) {
        return {
          url: '/riskFactor/create',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['riskFactorResponseDTO'],
    }),
    updateRiskFactor: builder.mutation<
      RiskFactorResponse,
      NewRiskFactorInput
    >({
      query(data) {
        return {
          url: `/riskFactor/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['riskFactorResponseDTO'],
    }),
    getAllRiskFactors: builder.query<RiskFactorResponse[], void>({
      query() {
        return {
          url: '/riskFactor/getall',
          credentials: 'include'
        };
      },
      providesTags: ['riskFactorResponseDTO'],
      transformResponse: (data: { riskFactorResponseDTO: RiskFactorResponse[] }) =>
        data.riskFactorResponseDTO
    })
  })
});

export const {
  useNewRiskFactorMutation,
  useUpdateRiskFactorMutation,
  useGetAllRiskFactorsQuery,
} = riskFactorApi;
