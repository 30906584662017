import axios from 'axios';

const axiosInt = axios.create({
  baseURL: 'http://69.197.148.110:7159/',
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInt.interceptors.request.use((config: any) => {
  console.log(JSON.stringify(config));

  return config;
});

export default axiosInt;
